import React from 'react'
import css from './Erosion.module.css'

import { Line } from 'react-chartjs-2'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

const buildJRegionChartData = combinations => {

    const returnValues = [];

    if(combinations && combinations.length > 0){

        let tagLength = combinations[0].jRegion.sequence.length;
        let rssLength = combinations[0].jRegion.RSSBases.length;

        const values = [];

        let sequenceCount = 0;

        for(let i = 0; i < tagLength+rssLength; i++){
            values.push(0);
        }

        combinations.forEach(c => {

            if(!c.jRegion.name.endsWith('X') && !c.vRegion.name.endsWith('X')){
                c.variableSequences.forEach(seq => {
                    for(let i = seq.jMatches.length-1; i >= 0; i--){
                        values[i] += +seq.jMatches[i];
                    }
                    sequenceCount++;     
                })

            }     
            
        })

    
        for(let i = 0; i < values.length; i++){
            returnValues.push({
                x: i,
                y: values[i] * 100 / sequenceCount
            })
        }

    }

    return returnValues;
}

const buildVRegionChartData = combinations => {

    const returnValues = [];
    if(combinations && combinations.length > 0){

        let tagLength = combinations[0].vRegion.sequence.length;
        let rssLength = combinations[0].vRegion.RSSBases.length;

        const values = [];

        let sequenceCount = 0;

        for(let i = 0; i < tagLength+rssLength; i++){
            values.push(0);
        }

        combinations.forEach(c => {

            if(!c.jRegion.name.endsWith('X') && !c.vRegion.name.endsWith('X')){
                c.variableSequences.forEach(seq => {
                    for(let i = 0; i <= seq.vMatches.length; i++){
                        values[i] += +seq.vMatches[i];
                    }
                    sequenceCount++;     
                })

            }     
            
        })

        
        for(let i = 0; i < values.length; i++){
            returnValues.push(
                values[i] * 100 / sequenceCount
            )
        }

    }

    return returnValues;

}

const negativeArray = arr => {
    const newArr = [];
    arr.forEach(e => newArr.push(-Math.abs(e)))
    return newArr
}

const erosion = props => {

    const jDataAlpha = buildJRegionChartData(props.combinations.filter(e => e.jRegion.locus === 'ALPHA'));
    const jDataBeta = buildJRegionChartData(props.combinations.filter(e => e.jRegion.locus === 'BETA'));
    const jDataGamma = buildJRegionChartData(props.combinations.filter(e => e.jRegion.locus === 'GAMMA'));

    const vDataAlpha = buildVRegionChartData(props.combinations.filter(e => e.vRegion.locus === 'ALPHA'));
    const vDataBeta = buildVRegionChartData(props.combinations.filter(e => e.vRegion.locus === 'BETA'));
    const vDataGamma = buildVRegionChartData(props.combinations.filter(e => e.vRegion.locus === 'GAMMA'));

    const len = vDataAlpha.length > 0 ? (vDataAlpha.length - 1) 
        : vDataBeta.length > 0 ? (vDataBeta.length - 1) 
        : vDataGamma.length > 0 ? (vDataGamma.length - 1) 
        : 0

    const jDataSet = {
        labels: Array.from(Array(len).keys()),
        datasets: [
            {
                data: jDataAlpha,
                backgroundColor: 'rgba(0,255,0,0.4)',
                label: 'Alphas'
            },
            {
                data: jDataBeta,
                backgroundColor: 'rgba(255,0,0,0.4)',
                label: 'Betas'
            },
            {
                data: jDataGamma,
                backgroundColor: 'rgba(0,0,255,0.4)',
                label: 'Gammas'
            }
        ]
    }

    

    let d = Array.from(Array(len).keys()).reverse();

    const vData = {
        labels: negativeArray(d),
        datasets: [
            {
                data: vDataAlpha,
                backgroundColor: 'rgba(0,255,0,0.4)',
                label: 'Alphas'
            },
            {
                data: vDataBeta,
                backgroundColor: 'rgba(255,0,0,0.4)',
                label: 'Betas'
            },
            {
                data: vDataGamma,
                backgroundColor: 'rgba(0,0,255,0.4)',
                label: 'Gammas'
            }
        ]
    }


    const commonScales = {
        yAxes: [
            {
                ticks: {
                    suggestedMax: 100,
                    suggestedMin: 0,
                    autoSkip: false
                },
                scaleLabel: {
                    display: true,
                    fontFamily: 'roboto',
                    labelString: 'Percentage of intact reads'
                }
            }
        ],
        xAxes: [
            {
                ticks: {
                    autoSkip: false
                },
                scaleLabel: {
                    display: true,
                    fontFamily: 'roboto',
                    labelString: 'Position relative to RSS'
                }
            }
        ]
    }

    const jOpts = {
        title: {
            display: true,
            fontFamily: 'roboto',
            text: 'J Region RSS Erosion'
        },
        scales: commonScales
    }

    const vOpts = {
        title: {
            display: true,
            fontFamily: 'roboto',
            text: 'V Region RSS Erosion'
        },
        scales: commonScales
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} >
                <Paper className={css.PaperWrapper}>
                    <Line data={vData} options={vOpts} />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} >
                <Paper className={css.PaperWrapper}>
                    <Line data={jDataSet} options={jOpts}/>
                </Paper>
            </Grid>
        </Grid>
    )

}

export default erosion