import React from 'react';

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import LoadedFileSummary from '../../UI/LoadedFileSummary/LoadedFileSummary'

import css from './Overview.module.css'

const overview = (props) => {

    let stats = Object.keys(props.stats).map(stat => {
        let s = props.stats[stat];
        return (
            <Grid item xs={6} sm={6} md={4} lg={3} key={s.label} >
                <Paper className={css.PaperItem}>
                    <p>{s.label}</p>
                    <span className={css.PaperItemInline}>
                        {
                           s.extraData ? <Chip label={`${s.count} (${s.extraData.label})`} color="primary"></Chip> : 
                                                <Chip label={`${s.count}`} color="primary"></Chip>
                        }
                    </span>
                </Paper>
            </Grid>
        )
    })

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper className={css.PaperTitle}>
                    <h1 >Overview</h1>
                    <LoadedFileSummary fileNames={props.fileNames} length="20" onDelete={props.onDelete}/>
                </Paper>
            </Grid>
            <Grid container item xs={12} spacing={2}  className={css.StatContainer}>
                {stats}
            </Grid>
        </Grid>
    )

}

export default overview;