
//data
export const DATA_STORE_FILE = 'DATA_STORE_FILE'
export const DATA_STORE_MULTIPLE = 'DATA_STORE_MULTIPLE'
export const DATA_FILTER = 'DATA_FILTER'
export const DATA_REMOVE_FILE = 'DATA_REMOVE_FILE'
export const DATA_SELECT_FILE = 'DATA_SELECT_FILE'
export const DATA_GET_SAMPLE = 'DATA_GET_SAMPLE'
export const DATA_START = 'DATA_START'
export const DATA_FETCH_FAIL = 'DATA_FETCH_FAIL'
export const DATA_FETCH_USER_RESULTS = 'DATA_FETCH_USER_RESULTS'

//user management
export const AUTH_START = 'AUTH_START'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_ERROR_CLEAR = 'AUTH_ERROR_CLEAR'
