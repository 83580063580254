import React, { Component } from 'react';

import MainNavigation from '../../components/Navigation/MainNavigation';
import css from './Layout.module.css'

class Layout extends Component {

    state = {
        showSideDrawer: false,
    }

    sideDrawerToggle = () => {
        this.setState((state) => ({
            showSideDrawer: !state.showSideDrawer
        }))
    }



    render(){

        let nav =  <MainNavigation 
                color="primary" 
                routes={this.props.routes} 
                onToggle={this.sideDrawerToggle}
                showDrawer={this.state.showSideDrawer}
            />                 
        


        return(
            <div className={css.FullViewHeight}>
                {nav}
                <div className={css.Content}>
                    {this.props.children}
                </div>
            </div>
            
        )
    }
}

export default Layout;