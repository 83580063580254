import React from 'react';
import { withRouter } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import classes from './SideDrawer.module.css'

const sideDrawer = (props) => {

    const routes = Object.keys(props.routes).map(el => {
        return (
           <ListItem button key={el} onClick={() => props.history.push(props.routes[el].link)} >
                <ListItemText primary={props.routes[el].label} />
          </ListItem>
          )
    })
    
    const list = 
        (<List className={classes.SideDrawer}>
            {routes}
         </List>)

    return (
      <Drawer open={props.showDrawer} onClose={props.onClose} >
        {list}
      </Drawer>

    );
}

export default withRouter(sideDrawer)