import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loadedFiles: {},
    selectedFiles: {},
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case(actionTypes.DATA_STORE_FILE): return storeNewResult(state, action)
        case(actionTypes.DATA_REMOVE_FILE): return removeResult(state, action)
        case(actionTypes.DATA_START): return {...state, loading: true, error: null}
        case(actionTypes.DATA_STORE_MULTIPLE): return storeMultipleResults(state, action)
        case(actionTypes.DATA_FILTER): return filterSelectedData(state, action)
        case(actionTypes.DATA_FETCH_FAIL): return {...state, loading: false, error: action.error}
        case(actionTypes.DATA_SELECT_FILE): return selectFile(state, action)
        default: return state; 
    }

}

const selectFile = (state, action) => {

    let file = state.loadedFiles[action.fileName];

    let selectedFiles = {
        ...state.selectedFiles,
    }

    selectedFiles[action.fileName] = file;

    if(file){
        return {
            ...state, 
            selectedFiles: selectedFiles
            
        }
    }

    return state;

}

const filterSelectedData = (state, action) => {

    let data = {}

    for(let key in state.loadedFiles){
        if(key === action.fileName){
            data[key] = state.loadedFiles[key]
        }
    }

    return {
        ...state,
        loading: false,
        selectedFiles: data
    }

}

const storeMultipleResults = (state, action) => {

    let data = {
        ...state.loadedFiles
    }

    for(let key in action.results){
        data[action.results[key].fileName] = action.results[key]

    }

    let selected = action.select ? data : state.selectedFiles;

    return {
        ...state,
        loading: false,
        loadedFiles: data,
        selectedFiles: selected,
        error: null
    }
}

const removeResult = (state, action) => {

    let newSelectedFiles = {};

    for(let key in state.selectedFiles){
        if(action.fileName !== key){
            newSelectedFiles[key] = state.selectedFiles[key]
        }
       
    }

    return {
        ...state,
        selectedFiles: newSelectedFiles
    }

}

const storeNewResult = (state, action) => {

    let data = {
        ...state.loadedFiles,
        [action.result.fileName]: action.result
    }

    let selectedData = action.select ? {
        ...state.selectedFiles,
        [action.result.fileName]: action.result
    } : state.selectedFiles

    return {
        ...state,
        loadedFiles: data,
        loading: false,
        error: null,
        selectedFiles: selectedData
    }
}

export default reducer;