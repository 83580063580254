import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import classes from './Login.module.css';

import { connect } from 'react-redux';
import * as actions from '../../store/actions'
import { Redirect } from 'react-router-dom';

class Login extends Component {

    state = {
        email: '',
        password: '',
        remember: false,
        isSignUp: false
    }

    mapErrorCodeToHumanMessage = code => {
        switch(code){
            case('TOKEN_EXPIRED'): return 'Login token is no longer valid, please sign in again.'
            case('USER_DISABLED'): return 'Your account has been disabled.'
            case('USER_NOT_FOUND'): return 'Your account cannot be found, please try signing up again.'
            case('INVALID_REFRESH_TOKEN'): return 'Cannot refresh authenticated. Please sign in again.'
            case('EMAIL_EXISTS'): return 'Email already exists.'
            case('OPERATION_NOT_ALLOWED'): return 'Sign up is currently disabled.'
            case('TOO_MANY_ATTEMPTS_TRY_LATER'): return 'You have attempted to login too many times. Please try again later.'
            case('EMAIL_NOT_FOUND'): return 'Invalid login details.'
            case('INVALID_EMAIL'): return 'Invalid login details.'
            case('INVALID_PASSWORD'): return 'Invalid login details.'           
            default: return code;
        }
    }

    submitHandler = event  => {
        event.preventDefault();  
        this.props.auth(this.state.email, this.state.password, this.state.isSignUp, this.state.remember)
    }

    handleChange = name => event => {
        this.setState({...this.state, [name]: event.target.value})
    }

    handleCheckBox = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    handleToggleSignIn = () => {
        this.setState({...this.state, isSignUp: !this.state.isSignUp})
    }

    render() {

        let message = this.state.isSignUp ? 'Sign Up' : 'Sign In'
        let linkMessage = this.state.isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'

        let error = null;

        if(this.props.error){
            error = (
           
                    <SnackbarContent 

                        className={classes.error}

                        message={this.mapErrorCodeToHumanMessage(this.props.error.message)}

                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={this.props.clearError}>
                              <CloseIcon className={classes.icon} />
                            </IconButton>
                          ]}
                    
                    />
             
            )
        }

        let authRedirect = this.props.isAuthenticated ? <Redirect to="/upload" /> : null;


        return (
            <Container component="main" maxWidth="xs">
            
                {authRedirect}
                <CssBaseline />
                <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {message}
                </Typography>
                <form className={classes.form}  onSubmit={this.submitHandler}>
                     {error}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={this.state.email}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange('email')}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox 
                            value="remember" 
                            color="primary" 
                            onChange={this.handleCheckBox('remember')}
                        />}                  
                    label="Remember me"
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    >
                    {message}
                    </Button>
                    <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                        Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={() => this.handleToggleSignIn()}>
                        {linkMessage}
                        </Link>
                    </Grid>
                    </Grid>
                </form>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return{
        loading: state.auth.loading,
        userId: state.auth.userId,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null
    }
}

const mapDispatchToProps = dispatch => {
    return{
        auth: (email, password, isSignUp, remember) => dispatch(actions.auth(email, password, isSignUp, remember)),
        clearError: () => dispatch(actions.authClearError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);