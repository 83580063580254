export const v = {
    
    "TRAV1-1":"21622567",
    "TRAV1-2":"21643578",
    "TRAV2":"21712843",
    "TRAV3":"21724321",
    "TRAV4":"21736982",
    "TRAV5":"21749705",
    "TRAV6":"21769080",
    "TRAV7":"21783503",
    "TRAV8-1":"21797886",
    "TRAV9-1":"21811977",
    "TRAV10":"21826075",
    "TRAV11":"21830070",
    "TRAV12-1":"21841774",
    "TRAV8-2":"21847221",
    "TRAV8-3":"21853006",
    "TRAV13-1":"21869365",
    "TRAV12-2":"21888502",
    "TRAV8-4":"21895030",
    "TRAV8-5":"21904598",
    "TRAV13-2":"21918756",
    "TRAV14DV4(SNP_C>T)":"21924651",
    "TRAV14DV4":"21924651",
    "TRAV9-2":"21941657",
    "TRAV15":"21950654",
    "TRAV12-3":"21966061",
    "TRAV8-6":"21979120",
    "TRAV16":"21990938",
    "TRAV17":"21998168",
    "TRAV18":"22003673",
    "TRAV19":"22008181",
    "TRAV20":"22041153",
    "TRAV21":"22053056",
    "TRAV22":"22071208",
    "TRAV23DV6":"22086961",
    "TRDV1":"22096619",
    "TRAV24":"22105846",
    "TRAV25":"22113031",
    "TRAV26-1":"22124285",
    "TRAV8-7":"22133034",
    "TRAV27":"22148633",
    "TRAV28":"22155638",
    "TRAV29DV5":"22163870",
    "TRAV30":"22168988",
    "TRAV31":"22177864",
    "TRAV32":"22186057",
    "TRAV33":"22190713",
    "TRAV26-2":"22203368",
    "TRAV34":"22208129",
    "TRAV35":"22222475",
    "TRAV36DV7(SNP_A>G)":"22227254",
    "TRAV36DV7":"22227254",
    "TRAV37":"22266264",
    "TRAV38-1":"22272563",
    "TRAV38-2DV8":"22281748",
    "TRAV39":"22304553",
    "TRAV40":"22314919",
    "TRAV41":"22320691",
    "TRAVDeltaRec1": "22386825",
    "TRAVDeltaRec2": "22427542",  
    "TRDV2":"22423042",
    "TRDV3":"22469041",
    "TRBV1":"142299460",
    "TRBV2":"142301432",
    "TRBV3-1":"142309048",
    "TRBV4-1":"142313666",
    "TRBV5-1":"142321544",
    "TRBV6-1":"142328786",
    "TRBV7-1":"142332701",
    "TRBV4-2":"142345985",
    "TRBV6-2":"142349664",
    "TRBV7-2":"142353358",
    "TRBV8-1":"142358917",
    "TRBV5-2":"142372912",
    "TRBV6-4":"142381261",
    "TRBV7-3":"142384841",
    "TRBV8-2":"142386647",
    "TRBV5-3":"142389668",
    "TRBV9":"142392412",
    "TRBV10-1":"142400377",
    "TRBV11-1":"142408136",
    "TRBV12-1":"142415666",
    "TRBV10-2":"142425465",
    "TRBV11-2":"142434394",
    "TRBV12-2":"142441325",
    "TRBV6-5":"142451448",
    "TRBV7-4":"142455635",
    "TRBV5-4":"142463581",
    "TRBV6-6":"142470013",
    "TRBV7-5":"142474567",
    "TRBV5-5":"142483019",
    "TRBV6-7":"142488295",
    "TRBV7-6":"142492673",
    "TRBV5-6":"142500534",
    "TRBV6-8":"142507810",
    "TRBV7-7":"142512127",
    "TRBV5-7":"142520556",
    "TRBV7-9":"142529762",
    "TRBV13":"142536292",
    "TRBV10-3":"142544685",
    "TRBV11-3":"142555318",
    "TRBV12-3":"142560931",
    "TRBV12-4":"142564245",
    "TRBV12-5":"142581427",
    "TRBV14":"142588359",
    "TRBV15":"142593473",
    "TRBV16":"142598469",
    "TRBV17":"142602360",
    "TRBV18":"142616415",
    "TRBV19":"142619532",
    "TRBV20-1":"142627399",
    "TRBV21-1":"142637384",
    "TRBV22-1":"142642196",
    "TRBV23-1":"142646467",
    "TRBV24-1":"142657213",
    "TRBV25-1":"142671244",
    "TRBVA":"142681869",
    "TRBV26":"142696183",
    "TRBVB":"142711924",
    "TRBV27":"142715861",
    "TRBV28":"142721160",
    "TRBV29-1":"142740894",
    "TRBV30":"142812586",
    "TRGV11":"38291616",
    "TRGVB":"38295763",
    "TRGV10":"38299811",
    "TRGV9":"38317017",
    "TRGVA":"38322446",
    "TRGV8":"38330343",
    "TRGV7":"38335041",
    "TRGV6":"38340700",
    "TRGV5P":"38345030",
    "TRGV5":"38349355",
    "TRGV4":"38353715",
    "TRGV3":"38358512",
    "TRGV2(SNP_A>G)":"38362864",
    "TRGV2":"38362864",
    "TRGV1":"38367586"

}

export const j = {"TRAJ61":"22475316",
    "TRAJ60":"22476306",
    "TRAJ59":"22476553",
    "TRAJ58":"22477707",
    "TRAJ57":"22478872",
    "TRAJ56":"22479521",
    "TRAJ55":"22481697",
    "TRAJ54":"22482287",
    "TRAJ53":"22483004",
    "TRAJ52":"22486228",
    "TRAJ51":"22487183",
    "TRAJ50":"22488593",
    "TRAJ49":"22489488",
    "TRAJ48":"22490491",
    "TRAJ47":"22492851",
    "TRAJ46":"22493403",
    "TRAJ45":"22493925",
    "TRAJ44":"22494821",
    "TRAJ43":"22495913",
    "TRAJ42":"22496887",
    "TRAJ41":"22497657",
    "TRAJ40":"22499689",
    "TRAJ39":"22501601",
    "TRAJ38":"22502231",
    "TRAJ37":"22503750",
    "TRAJ36":"22505110",
    "TRAJ35":"22506644",
    "TRAJ34":"22507666",
    "TRAJ33":"22508602",
    "TRAJ32":"22509341",
    "TRAJ31":"22510968",
    "TRAJ30":"22512852",
    "TRAJ29":"22513939",
    "TRAJ28":"22515623",
    "TRAJ27":"22516273",
    "TRAJ26":"22518446",
    "TRAJ25":"22518812",
    "TRAJ24":"22519969",
    "TRAJ24(SNP_C>G)":"22519969",
    "TRAJ23":"22520416",
    "TRAJ22":"22522040",
    "TRAJ21":"22523600",
    "TRAJ20":"22524325",
    "TRAJ19":"22525263",
    "TRAJ18":"22525650",
    "TRAJ17":"22526844",
    "TRAJ16":"22528527",
    "TRAJ15":"22529629",
    "TRAJ14":"22530327",
    "TRAJ13":"22531076",
    "TRAJ12":"22531939",
    "TRAJ11":"22532502",
    "TRAJ10":"22533497",
    "TRAJ9":"22535554",
    "TRAJ8":"22536145",
    "TRAJ7":"22537622",
    "TRAJ6":"22539073",
    "TRAJ5":"22540247",
    "TRAJ4":"22542199",
    "TRAJ3":"22543179",
    "TRAJ2":"22544071",
    "TRAJ1":"22545037",
    "TRBJ1-1":"142786880",
    "TRBJ1-2":"142787017",
    "TRBJ1-3":"142787630",
    "TRBJ1-4":"142788225",
    "TRBJ1-5":"142788498",
    "TRBJ1-6(SNP_T>C)":"142788988",
    "TRBJ1-6":"142788988",
    "TRBJ2-1":"142796365",
    "TRBJ2-2":"142796560",
    "TRBJ2-2P":"142796697",
    "TRBJ2-3":"142796847",
    "TRBJ2-4":"142796998",
    "TRBJ2-5":"142797119",
    "TRBJ2-6":"142797239",
    "TRBJ2-7":"142797456",
    "TRDJ1":"22450089",
    "TRDJ4":"22455249",
    "TRDJ2":"22456689",
    "TRDJ3":"22459098",
    "TRGJ2":"38253429",
    "TRGJP2":"38256396",
    "TRGJ1":"38269540",
    "TRGJP":"38273647",
    "TRGJP1":"38276318"}