import React, { Component } from 'react';


import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import LoadedFileSummary from '../../components/UI/LoadedFileSummary/LoadedFileSummary'
import Spinner from '../../components/UI/Spinner/Spinner'

import css from './Upload.module.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Link } from '@material-ui/core';

class Upload extends Component {

    state = {
        files: [],
        fileMessage: ''
    }

    componentDidMount(){
        this.setDefaultState();
    }
    
    setDefaultState(){
        
        this.setState({
            files: [],
            fileMessage: 'Drag \'n\' drop some files here, or click to select files'
        })
    }

    onFilesSelectedHandler = (files) => {
        if(files.length > 0){
            let fileNames = [];

            for(let file in files){
                let fileName = files[file].name;
                fileNames.push(fileName.length > 20 ? fileName.substring(0, 20) + '...' : fileName);
            }

            this.setState({
                files: files,
                fileMessage: fileNames.join(', ')
            })
        }else{
            this.setDefaultState();
        }
    }

    onContinueHandler = () => {
        this.props.history.push('/dashboard')
    }

    onSelectedFileSubmit = () => {

        if(this.state.files.length > 0){
            for(let i in this.state.files){
                const file = this.state.files[i];
                var reader = new FileReader();

                reader.onload = res => {
                    const json = JSON.parse(res.currentTarget.result)
                    json.fileName = file.name;
                    this.props.onStoreResult(json, this.props.userId, this.props.token);
                }

                reader.onerror = err => {
                    console.log(err);
                }
 
                reader.readAsText(file, 'utf-8');
            }            
        }
    }

    render(){

        let upload = <Spinner />;
        if(!this.props.loading){

            let continueButton = null;

            if(this.props.loadedFiles.length > 0){
                continueButton = <Button color="primary" variant="contained" onClick={() => this.onContinueHandler()}>Continue</Button>
            }

            let summary = null;
            if(this.props.selectedFiles.length > 0){
                summary = (
                    <>
                    <LoadedFileSummary 
                    fileNames={this.props.selectedFiles} 
                    length="30" 
                    onDelete={(fileName) => this.props.onRemoveFile(fileName)}
                    />
                    {continueButton}
                    </>
                )
            }

            upload = (
                <div className={css.UploadWrapper} id="upload-div">
                    <h1 className={css.UploadTitle}>Upload</h1>
                    <p>This should be a valid .json file from the SBL T-cell pipeline</p>
                    <Dropzone onDrop={acceptedFiles => this.onFilesSelectedHandler(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <section className={css.DropZone}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>{this.state.fileMessage}</p>
                            </div>
                            </section>
                        )}
                    </Dropzone>
                    <Button color="primary" variant="contained" onClick={() => this.onSelectedFileSubmit()}>Submit</Button>
                    <p>curious? try some <Link onClick={() => this.props.onGetSampleData()} className={css.SampleLink}>sample data</Link></p>
                    {summary}              
                </div>
            )

        }

        return upload
    }
}

const mapStateToProps = state => {
    return{
        loadedFiles: Object.keys(state.data.loadedFiles),
        selectedFiles: Object.keys(state.data.selectedFiles),
        loading: state.data.loading,
        userId: state.auth.userId,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onStoreResult: (result, userId, token) => dispatch(actions.uploadFileAndSaveToRedux(result, userId, token, true)),
        onRemoveFile: (fileName) => dispatch(actions.removeFileData(fileName)),
        onGetSampleData: () => dispatch(actions.getSampleData(true)),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload);