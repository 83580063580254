import React from 'react';

import css from './LoadedFileSummary.module.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const loadedFileSummary = (props) => {

    let loadedFiles = <p className={css.FileSummaryPlaceholder}>Load files to get started</p>

    if(props.fileNames && props.fileNames.length > 0){
        loadedFiles = (
        
            <List>
                {props.fileNames.map(el => {
                    return (
                        <ListItem key={el}>
                            <ListItemText>
                                {el.length > +props.length ? el.substring(0, 20) + '...' + el.substring(el.length - 15, el.length) : el}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => props.onDelete(el)}>
                                <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>  
                        </ListItem>
                    )
                })}
            </List>
            );  
    }


    return(
        <div>
             <h3 className={css.FileSummaryTitle}>Loaded files:</h3>
            {loadedFiles}
        </div>
    );
}

export default loadedFileSummary;