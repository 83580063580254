import React, { Component, Fragment } from 'react';

import MainNavigation from '../../components/Navigation/MainNavigation'
import landingImage from '../../colors.png'
import { withRouter} from 'react-router-dom';
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import css from './Index.module.css'

class Index extends Component {

    state = {
       // currentScrollHeight: 0,
        showDrawer: false
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({
            showDrawer: !state.showDrawer
        }))
    }

    // componentDidMount(){
    //     window.addEventListener('scroll', this.handleScroll)
    // }

    // handleScroll = () => {
    //     this.setState({currentScrollHeight: window.scrollY})
    // }

    render(){

       // let navClasses = [];

        // if(window.scrollY > 40){
        //     navClasses.push(css.FixedNav)
        // }else{
        //     navClasses.push(css.TopNav)
        // }

        return(
            <Fragment >
                <div >
                  <MainNavigation 
                    isTransparent={true} //{window.scrollY <= 40} 
                    color="default" 
                    routes={this.props.routes}
                    //onToggle={this.handleDrawerToggle}
                    showDrawer={this.state.showDrawer}
                    />
                </div>
            <img src={landingImage} className={css.IndexImage} alt='main landing'/>
            <div className={css.IndexTitleBox}>
                <h1 className={css.IndexTitle}>VDJ GO</h1>
                <h2 className={css.IndexSubtitle}>The VDJ Recombination Analysis Tool</h2>
            </div>   
            {/* <div className={css.CardWrapper}> */}
            <Card className={css.IndexCard}>
                <h1>Get Started</h1>
                <p>Required input is a VDJGO .json file from the SBL T-cell pipe workflow</p>
                <Button onClick={() => this.props.history.push('/upload')} variant="contained" color="primary">Upload Now</Button>
            </Card>
            {/* </div> */}
            
            </Fragment>
        )
    }
}

export default withRouter(Index);