import React, { Component } from 'react';

import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Spinner from '../../components/UI/Spinner/Spinner'
import SideMenu from '../../components/UI/SideMenu/SideMenu'

import Overview from '../../components/Dashboard/Overview/Overview'
import Dartboard from '../../components/Dashboard/Dartboard/Dartboard'
import Heatmap from '../../components/Dashboard/Heatmap/Heatmap'
import Erosion from '../../components/Dashboard/Erosion/Erosion'
import Histogram from '../../components/Dashboard/Histogram/Histogram'
import Info from '../../components/Dashboard/Info/Info';

import * as actions from '../../store/actions/index';
import * as utils from './utlities/dashboardUtils';
import css from './Dashboard.module.css';


import _ from 'lodash';

class Dashboard extends Component {

    state = {
        menuItems: {
            overview: {
                label: 'Overview'
            },
            dartboard: {
                label: 'Dartboard'
            },
            heatmap: {
                label: 'Heatmap'
            },
            cdr3Histogram: {
                label: 'CDR3 Histogram'
            },
            info: {
                label: 'Info'
            }
        },
        view: 'Overview',
        combinations: [],
        showModal: false
        
    }


    refreshCombinations(){       

        let combinations = [];
        const length = Object.keys(this.props.files).length
        if(length >0){
            if(length === 1){
                combinations = [...this.props.files[Object.keys(this.props.files)[0]].combinations];           
           }else{
                let comboLists = [];
                Object.keys(this.props.files).forEach(f => {
            
                    comboLists.push(_.cloneDeep(this.props.files[f].combinations))
                    
                })
                combinations = utils.concatCombinations(comboLists)
           }
        }

        this.setState({combinations: combinations})
    }

    componentDidMount = () => this.refreshCombinations();

    shouldComponentUpdate = (nextProps, nextState) => {
       
        if(Object.keys(this.props.files).length !== Object.keys(nextProps.files).length){
            return true;
        }

        if(this.state.combinations.length !== nextState.combinations.length){
            return true;
        }

        if(this.state.view !== nextState.view){
            return true;
        }

        return false;
    }

    componentDidUpdate = () =>  this.refreshCombinations();

    onSubmenuClickedHandler = (label) => {
        this.setState({view: label})
    }

    onFileDeletedHandler = (fileName) => {
        this.props.onRemoveFile(fileName);
        this.refreshCombinations();
    }

    render(){

        const alphas = this.state.combinations.filter(e => e.jRegion.locus === 'ALPHA')
        const betas = this.state.combinations.filter(e => e.jRegion.locus === 'BETA')
        const gammas = this.state.combinations.filter(e => e.jRegion.locus === 'GAMMA')
        const total = alphas.length + betas.length + gammas.length;

        const variableSequences = [];

        this.state.combinations.forEach(combo => {
            combo.variableSequences.forEach(e => variableSequences.push(e))
        });

        const productiveSequences = [];
        const nonProductiveSequences = [];

        variableSequences.forEach(seq => {
            if(seq.aminoCombination.isProductive){
                productiveSequences.push(seq);
            }else{
                nonProductiveSequences.push(seq);
            }
        })
    
        let stats = [    
            {
                count: alphas.length,
                label: 'Alpha Combinations',
                extraData: {
                    label: (alphas.length/total * 100).toFixed(2) +  '%',
                    color: 'primary'
                }
            },
            {
                count: betas.length,
                label: 'Beta Combinations',
                extraData: {
                    label: (betas.length/total * 100).toFixed(2) + '%',
                    color: 'primary'
                }
            },
            {
                count: gammas.length,
                label: 'Gamma Combinations',
                extraData: {
                    label: (gammas.length/total * 100).toFixed(2) + '%',
                    color: 'primary'
                }
            },
            {
                count: total,
                label: 'Total Combinations',
                extraData: {
                    label: '100%',
                    color: 'primary'
                }
            },
            {
                count: variableSequences.length,
                label: 'Total Variable Sequences'
            },
           {
                count: productiveSequences.length,
                label: 'Productive Sequences'
              },
           {
                count: nonProductiveSequences.length,
                label: 'Non-Productive Sequences'
            },
            {
                count: this.state.combinations.length > 0 ?  utils.calculateClonality(this.state.combinations).toFixed(3) : 0,
                label: 'Total Clonality'
            },
           {
                count: alphas.length > 0 ? utils.calculateClonality(alphas).toFixed(3) : 0,
                label: 'Alpha Locus Clonality'
            },
            {
                count: betas.length > 0 ? utils.calculateClonality(betas).toFixed(3) : 0,
                label: 'Beta Locus Clonality'
            },
            {
                count: gammas.length > 0 ? utils.calculateClonality(gammas).toFixed(3) : 0,
                label: 'Gamma Locus Clonality'
            }
        ]
  

        let currentView = null;

        switch(this.state.view){
            case(this.state.menuItems.dartboard.label): currentView = <Dartboard combinations={this.state.combinations} />
                break;
            case(this.state.menuItems.heatmap.label): currentView = <Heatmap combinations={this.state.combinations} />
                break;   
            case(this.state.menuItems.cdr3Histogram.label): currentView = <Histogram combinations={this.state.combinations} />
                break;    
            case(this.state.menuItems.info.label):{
                const info=[];
                Object.keys(this.props.files).forEach(key => {
                    info.push({
                        fileName: this.props.files[key].fileName,
                        data: this.props.files[key].info
                    })
                })
                currentView = <Info info={info} />
                break;
            }   
            default: currentView = <> 
                    <Overview 
                    combinations={this.state.combinations}
                    stats={stats.filter(e => e.count !== 0)} 
                    fileNames={Object.keys(this.props.files)}
                    onDelete={(fileName) => this.onFileDeletedHandler(fileName)} 
                    />
                    <br />
                    { !_.isEmpty(this.props.files) && 
                      this.props.files[Object.keys(this.props.files)[0]].type !== "signal" ? 
                      <Erosion combinations={this.state.combinations} className={css.Erosion} /> : 
                      null } 
                </>
        }

        let loadedContent = <h2>Upload some data to get started</h2>

        if(this.state.combinations.length > 0){
            loadedContent = currentView;
        }

        if(this.props.loading){
            console.log('loading')
            loadedContent = <Spinner />
        }

        return(
            <div className={css.root}>
                <Grid container spacing={2} className={css.GrayBackground}> 
                    <Grid item xs={12} sm={3} lg={2} xl={1} >
                    <Paper className={css.Paper + " " + css.PaperMenu}>
                        <SideMenu items={this.state.menuItems} clicked={(label) => this.onSubmenuClickedHandler(label)}/>
                    </Paper>
                    </Grid>
                    <Grid item  xs={12} sm={9} lg={10} xl={11}>  
                        {loadedContent}
                    </Grid>               
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        files: state.data.selectedFiles,
        loading: state.data.loading
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onGetSampleData: () => dispatch(actions.getSampleData()),
        onRemoveFile: (fileName) => dispatch(actions.removeFileData(fileName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);