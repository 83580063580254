import React from 'react'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import css from './Info.module.css'

const info = props => {

     const infoLayout = props.info.map(info => {
        const len = info.fileName.length; 
        const name = len > 40 ? info.fileName.substring(0, 20) + '...' + info.fileName.substring(len - 15, len) : info.fileName 

        return (
            <ExpansionPanel className={css.PaperWrapper} key={info.fileName}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={css.Heading}
                    >
                    <Typography className={css.Heading} >{name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails >
                        <ul className={css.Content}>
                        {
                          
                            info.data.map((i, index) => {
                                return(
                                    <li key={index} className={css.ContentInner} >                           
                                        {i}
                                    </li>
                                )
                            })
                            
                        }
                        </ul>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    })

    return(
        <>
        <Paper className={css.PaperWrapper}>
            <h2>Info</h2>
          
        </Paper>
          {infoLayout}
        </>
    )

}

export default info;