import React from 'react';

import classes from './Result.module.css'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const result = (props) => {

    const name = props.result.fileName;

    return (
        <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {name.length < 40 ? name : `${name.slice(0, 10)}...${name.slice(name.length-20, name.length)} `}
        </Typography>
        <Typography variant="h5" component="h2">
          Combinations:  {props.result.combinations.length} 
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          type: {props.result.type}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" size="small" onClick={() => props.viewClicked(props.result.fileName)}>View</Button>
        <Button variant="contained" color="primary" size="small" onClick={() => props.selectClicked(props.result.fileName)}>Add</Button>
      </CardActions>
    </Card>
    )
}

export default result;