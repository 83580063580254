import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'

import Spinner from '../../components/UI/Spinner/Spinner'
import LoadedFileSummary from '../../components/UI/LoadedFileSummary/LoadedFileSummary'
import Grid from '@material-ui/core/Grid'
import Result from './Result/Result'
import classes from './Results.module.css';
import Button from '@material-ui/core/Button'

class Results extends Component {

    componentDidMount(){
        if(Object.keys(this.props.files).length === 0){
            this.props.onFetchResults(this.props.token, this.props.userId);
        }
    }

    onViewClickedHandler = (fileName) => {
        this.props.onFilterResults(fileName)
        this.props.history.push('/dashboard')
    }

    onSelectClickedHandler = (fileName) => {
        this.props.onSelectFile(fileName);
    }

    onContinueHandler = () => {
        this.props.history.push('/dashboard')
    }


    render(){

        let page = null;

        let data = null;

        if(Object.keys(this.props.files).length > 0 && !this.props.loading){

            let cards = [];
            Object.keys(this.props.files).forEach(el => {
                cards.push(
                    <Grid item xs={12} sm={12} md={4} lg={3} key={el}>
                            <Result result={this.props.files[el]} viewClicked={this.onViewClickedHandler} selectClicked={this.onSelectClickedHandler} />
                    </Grid>
               
                )
            })

            data = <Grid container spacing={2}>
                {cards}
            </Grid>


        }

        let continueButton = null;
        if(Object.keys(this.props.selectedFiles).length > 0){
            continueButton = <Button color="primary" variant="contained" onClick={() => this.onContinueHandler()}>Continue</Button>
        }
        
        if(this.props.loading){
            page =  <Spinner />
        }else{
            page = (
                <div className={classes.Results}>
                    <h1>Saved Data</h1>
                    {data}
                    <>
                    <LoadedFileSummary 
                    fileNames={Object.keys(this.props.selectedFiles)} 
                    length="30" 
                    onDelete={(fileName) => this.props.onRemoveFile(fileName)}
                    />
                    {continueButton}
                    </>
                </div>
            )
        }

        if(this.props.error){
            page = (
                <div className={classes.Error}>
                <h1>Oops, something went wrong...</h1> 
                <p>{this.props.error}</p>
                <Button variant="contained" onClick={() => this.props.history.push('/')} color="primary">Home</Button>
                </div>
            )
        }




        return(
            <>
                {page}
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        userId: state.auth.userId,
        token: state.auth.token,
        loading: state.data.loading,
        selectedFiles: state.data.selectedFiles,
        files: state.data.loadedFiles,
        error: state.data.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchResults: (token, userId) => dispatch(actionTypes.getUserResults(token, userId, false)),
        onFilterResults: (fileName) => dispatch(actionTypes.filterData(fileName)),
        onRemoveFile: (fileName) => dispatch(actionTypes.removeFileData(fileName)),
        onSelectFile: (fileName) => dispatch(actionTypes.selectFile(fileName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);