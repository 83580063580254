
export const concatCombinations = combinationsArray => {

    let allCombinations = {};
    combinationsArray.forEach(combinations => {
        combinations.forEach(combination => {
            const key = combination.jRegion.name + combination.vRegion.name;
            if(allCombinations[key]){
                //loop over new sequences
                combination.variableSequences.forEach(combinationSequence => {
                    let found = false;
                    //loop over already found variable sequences
                    for(let i in allCombinations[key].variableSequences){
                        let seq = allCombinations[key].variableSequences[i];
                        if(combinationSequence.sequence === seq.sequence){
                            seq.ids = seq.ids.concat(combinationSequence.ids);
                            found = true;
                            break;
                        }
                    }

                    if(!found){
                        allCombinations[key].variableSequences.push({...combinationSequence});
                    }

                })
            
            }else{
                allCombinations[key] = {...combination};
            }
        })
        
    })

    return Object.values(allCombinations)
}

export const calculateClonality = combinations => {

    let totalVariableSequences = 0; //s
    let totalMatchedReads = 0; //total population

    combinations.forEach(combination => {
        totalVariableSequences += combination.variableSequences.length;
        combination.variableSequences.forEach(seq => {
            totalMatchedReads += seq.ids.length;
        })
    })

    let shannonEntropy = 0;

    combinations.forEach(combination => {
        combination.variableSequences.forEach(seq => {
            let proportion = seq.ids.length / totalMatchedReads
            let sum = proportion * Math.log10(proportion);
            shannonEntropy += sum;
        })
    })

    let pielousEvenness = -shannonEntropy / Math.log10(totalVariableSequences)

    return 1 - pielousEvenness;
}