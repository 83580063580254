import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'

import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange'
import { ThemeProvider } from '@material-ui/styles';

import dataReducer from './store/reducers/data';
import authReducer from './store/reducers/user'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';

const rootReducer = combineReducers({
    data: dataReducer,
    auth: authReducer
})

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#309096',
            contrastText: '#fff'
        },
        secondary: orange,
        success: {
            main: '#76ff03',
            contrastTest: '#fff'
        }
      },
});

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;


const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>   
)

const root = document.getElementById('root');

ReactDOM.render(app, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
