import * as actionTypes from './actionTypes';
import axios from '../../axios-vdjgo';

export const uploadFileAndSaveToRedux = (fileResult, userId, token, select) => {
    return dispatch => {

        if(token){
            dispatch(startLoading())
            fileResult.userId = userId
            axios.post(`/combinations.json?auth=${token}`, fileResult)
            .then(response => {
                dispatch(storeFileResultLocally(fileResult, select))
            })
            .catch(err => {
                dispatch(dataFetchFail(err.message))
            })

        }else{
            dispatch(storeFileResultLocally(fileResult, select))
        }      
    }
}

export const dataFetchFail = (error) => {
    return {
        type: actionTypes.DATA_FETCH_FAIL,
        error: error
    }
}

export const filterData = (fileName) => {
    return {
        type: actionTypes.DATA_FILTER,
        fileName: fileName
    }
}

export const selectFile = (fileName) => {
    return {
        type: actionTypes.DATA_SELECT_FILE,
        fileName: fileName
    }
}

export const storeFileResultLocally = (fileResult, select) => {
    return {
        type: actionTypes.DATA_STORE_FILE,
        result: fileResult,
        select: select
    }
}

export const removeFileData = (fileName) => {
    return {
        type: actionTypes.DATA_REMOVE_FILE,
        fileName: fileName
    }
}

export const storeMultipleResults = (results, select) => {
    return {
        type: actionTypes.DATA_STORE_MULTIPLE,
        results: results,
        select: select
    }
}

export const startLoading = () => {
    return {
        type: actionTypes.DATA_START
    }
}

export const getSampleData = (select) => {
    return dispatch => {
        dispatch(startLoading())
        axios.get('/dev.json')
            .then(response => {
                for(let key in response.data){
                    let json = response.data[key];
                    json.fileName = key; 
                    dispatch(storeFileResultLocally(json, select))      
                }
            })
            .catch(err => {
                dispatch(dataFetchFail(err.message))
            })
        
    }    
}

export const getUserResults = (token, userId, select) => {
    return dispatch => {
        dispatch(startLoading())
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"'
        axios.get(`/combinations.json${queryParams}`)
            .then(res => {
                let data = {}
                for(let key in res.data){
                    data[key] = res.data[key];           
                }
                dispatch(storeMultipleResults(data, select))
            })
            .catch(err => {
                dispatch(dataFetchFail(err.message))
                
            })
    }
}