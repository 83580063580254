import React, { Component } from 'react'

import css from './Heatmap.module.css'

import { Bubble } from 'react-chartjs-2'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as order from './utils/order'

class Heatmap extends Component {

    state = {
        controls:{
            locus: [
                    {
                        state: 'checkedAlpha',
                        label: 'Alphas'
                    },
                    {
                        state: 'checkedBeta',
                        label: 'Betas'
                    },
                    {
                        state: 'checkedGamma',
                        label: 'Gammas'
                    }
                ],
            productive: [
                {
                    state: 'checkedProductive',
                    label: 'Productive'
                },
                {
                    state: 'checkedNonProductive',
                    label: 'Non Productive'
                }
            ],
            additional: [
                {
                    state: 'isLogarithmic',
                    label: 'Logarithmic'
                }
            ]
        },
        checkedAlpha: true, 
        checkedBeta: true,
        checkedGamma: true,
        checkedProductive: true,
        checkedNonProductive: false,
        isLogarithmic: false,
        combination: null
    }

    createData = combinations => {

        let vees = [];
        let jays = [];
        const data = [];

        //todo this might be duplicate code.
        const combos = combinations.filter(e => {
     
            if(e.jRegion.locus === 'ALPHA' && !this.state.checkedAlpha){
                return false;
            }

            if(e.jRegion.locus === 'BETA' && !this.state.checkedBeta){
                return false;
            }

            if(e.jRegion.locus === 'GAMMA' && !this.state.checkedGamma){
                return false;
            }

            let valid = false;
            e.variableSequences.forEach(seq => {
                if((this.state.checkedProductive && seq.aminoCombination.isProductive) ||
                (this.state.checkedNonProductive && !seq.aminoCombination.isProductive)){
                    valid = true
                }
            })
            return valid;
        })

        //populate the v's and j's.
        for(let i in combos){
            const combination = combos[i];

            let vIndex = this.findIndex(vees, combination.vRegion.name);
            let jIndex = this.findIndex(jays, combination.jRegion.name);
    
            if(vIndex === -1){
                vees.push(combination.vRegion.name);
                vIndex = vees.length - 1;
            }
    
            if(jIndex === -1){
                jays.push(combination.jRegion.name);
                jIndex = jays.length - 1; 
            }
        }

        vees = vees.sort((a,b) => {
            const v1 = order.v[a] ? order.v[a] : Number.MAX_SAFE_INTEGER
            const v2 = order.v[b] ? order.v[b] : Number.MAX_SAFE_INTEGER
            return v1 - v2
        })

        jays = jays.sort((a,b) => {
            const j1 = order.j[a] ? order.j[a] : Number.MAX_SAFE_INTEGER
            const j2 = order.j[b] ? order.j[b] : Number.MAX_SAFE_INTEGER
            return j1 - j2
        })
    
        for(let i in combos){
            const combination = combos[i];
    
            let vIndex = this.findIndex(vees, combination.vRegion.name);
            let jIndex = this.findIndex(jays, combination.jRegion.name);
    
            let numSequences = 0;
    
            combination.variableSequences.forEach(e => {
                if((this.state.checkedProductive && e.aminoCombination.isProductive) || 
                (this.state.checkedNonProductive && !e.aminoCombination.isProductive)){
                    numSequences += e.ids.length;
                }
            })
    
            data.push({
                x: vIndex,
                y: jIndex,
                r: this.state.isLogarithmic ? Math.log2(numSequences) : numSequences
            })    
        }
    
        const cData = {
            datasets: [
                {
                    data: data,
                    backgroundColor: 'rgba(0, 0, 255, 0.2)'
                }
            ]
        }
    
        const options = {
            legend: false,
            scales: {
                xAxes: [
                    {
                        type: 'linear',
                        position: 'bottom',
                        ticks: {
                            min: 0,
                            max: vees.length - 1,
                            stepSize: 1,
                            autoSkip: false,
                            callback: val => {
                                return vees[val];
                            }
                        }
                    }
                ],
                yAxes: [
                    {
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            min: 0,
                            max: jays.length - 1,
                            stepSize: 1,
                            autoSkip: false,
                            callback: val => {
                                return jays[val];
                            }
                        }
                    }
                ]
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: (items, data) => {
                        let val = 0;
                        for(let d in data.datasets[0].data){                         
                            if(data.datasets[0].data[d].x === items.xLabel && data.datasets[0].data[d].y === items.yLabel){
                                val = data.datasets[0].data[d].r;
                                break;
                            }
                        }
                        if(this.state.isLogarithmic){
                            val = Math.pow(2, val);
                        }
                        return `${vees[items.xLabel]}, ${jays[items.yLabel]}: ${val.toFixed(0)}`
                    }
                }
            },
            onClick: (e, element) => {

                // If you click on at least 1 element ...
                if (element.length > 0) {
               
                    // Logs it

                    const point = cData.datasets[0].data[element[0]._index];
                    const v = vees[point.x];
                    const j = jays[point.y];

                    let combination = null;

                    combinations.forEach(c => {
                        if(c.jRegion.name === j && c.vRegion.name === v){
                            combination = c;
                        }
                    })

                    this.setState({combination: combination})
                   
                }
            }
        }

 
    
        let chartData = [];
    
        chartData.push(cData);
        chartData.push(options);
    
        return chartData;
    }

    handleChange = which => event => {
        this.setState({
            [which]: event.target.checked
        })   
    }

    mapControlToFormControlSwitch = controls => {
        return controls.map(control => {
            return (
                <FormControlLabel 
                    key={control.state}
                    control = {
                        <Switch                        
                        color="primary"
                        checked={this.state[control.state]}
                        onChange={this.handleChange(control.state)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    }
                    label={control.label}
                />
            )
        })
    }
    
    findIndex = (list, string) => {
        for(let i = 0; i < list.length; i++){
            if(list[i] === string){
                return i;
            }
        }
    
        return -1;
    }

    render(){
    const data = this.createData(this.props.combinations);

    const allControls = [
        {
            label: 'Locus',
            controls: this.mapControlToFormControlSwitch(this.state.controls.locus)
        },
        {
            label: 'Productivity',
            controls: this.mapControlToFormControlSwitch(this.state.controls.productive)
        },
        {
            label: 'Additional',
            controls: this.mapControlToFormControlSwitch(this.state.controls.additional)
        }
    ]

    let renderedControls = allControls.map(el => {
        return (
            <Grid item xs={12} sm={4} key={el.label}>
                <FormControl >
                <FormLabel >{el.label}</FormLabel>
                    <FormGroup>
                    {el.controls}
                    </FormGroup>
                </FormControl>
            </Grid>
        )
    })

    //todo this needs sorting by combination

    let renderedCombo = null;
    if(this.state.combination){
        renderedCombo = (
            <Grid item xs={12}>
                <Paper className={css.HeatmapWrapper}>
                    <h3>{`${this.state.combination.vRegion.name} - ${this.state.combination.jRegion.name}` }</h3>
                    {
                        this.state.combination.variableSequences.map(seq => {
                            return (
                                <ExpansionPanel key={seq.sequence}>
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography >{seq.sequence}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <List dense={true}>
                                    {
                                        seq.ids.map(id => {
                                            return(
                                                <ListItem key={id}>
                                                    <ListItemText 
                                                        primary={id}
                                                    />
                                                </ListItem>
                                            )
                                           

                                        })
                                    }
                                    </List>
                                    
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        })
                    }
                </Paper>
            </Grid>
        )
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>                
                <Paper className={css.HeatmapWrapper}>
                    <h2>Filter:</h2>
                    <Grid container spacing={2}>
                        {renderedControls} 
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={css.HeatmapWrapper}>
                    <Bubble data={data[0]} options={data[1]}/>
                </Paper>
            </Grid>
            {renderedCombo}
        </Grid>
        )   
    }
}

export default Heatmap;