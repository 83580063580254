import React, { Component } from 'react';

import css from './Dartboard.module.css'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'

import { Doughnut } from 'react-chartjs-2'

const ranHex = () => {
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
}

class dartboard extends Component {

    state = {
        options: {
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            maintainAspectRatio: false,
            legend: false
        },
        controls:{
            locus: [
                    {
                        state: 'checkedAlpha',
                        label: 'Alphas'
                    },
                    {
                        state: 'checkedBeta',
                        label: 'Betas'
                    },
                    {
                        state: 'checkedGamma',
                        label: 'Gammas'
                    }
                ],
            productive: [
                {
                    state: 'checkedProductive',
                    label: 'Productive'
                },
                {
                    state: 'checkedNonProductive',
                    label: 'Non Productive'
                }
            ],
            merges: [
                {
                    state: 'checkedMergeOnes',
                    label: 'Merge All Unique'
                },
                {
                    state: 'checkedMergeTwos',
                    label: 'Merge All Twos'
                },
                {
                    state: 'checkedMergeThrees',
                    label: 'Merge All Threes'
                }
            ]
        },
        checkedAlpha: true, 
        checkedBeta: true,
        checkedGamma: true,
        checkedProductive: true,
        checkedNonProductive: false,
        checkedMergeOnes: true,
        checkedMergeTwos: true,
        checkedMergeThrees: true
    }

    filterBasedOnNumberOfIds = (data, value, total, label) => {
        data = data.filter(combo => {
            return combo.data !== value
        });
        data.push({
            data: total,
            label: label,
            color: ranHex()
        })
        return data;
    }

    constructData = (combinations) => {
        const chartData = [];
        const chartLabels = [];
        const backgroundColors = [];
        let cData = [];
    
        const combos = combinations.filter(e => {
            if(e.jRegion.locus === 'ALPHA' && !this.state.checkedAlpha){
                return false;
            }

            if(e.jRegion.locus === 'BETA' && !this.state.checkedBeta){
                return false;
            }

            if(e.jRegion.locus === 'GAMMA' && !this.state.checkedGamma){
                return false;
            }
    
            return true;
        })

        let countOnes = 0;
        let countTwos = 0;
        let countThrees = 0;
    
        combos.forEach(e => {
            e.variableSequences.forEach(seq => {
                if((this.state.checkedProductive && seq.aminoCombination.isProductive) || 
                    (this.state.checkedNonProductive && !seq.aminoCombination.isProductive)){

                        if(seq.ids.length === 1) countOnes++;
                        else if(seq.ids.length === 2) countTwos++;
                        else if(seq.ids.length === 3) countThrees++;

                        cData.push({
                            data: seq.ids.length,
                            label: `${e.jRegion.name}, ${e.vRegion.name}: ${seq.sequence}`,
                            color: ranHex()
                        })
                    }                
            })
        })

        if(this.state.checkedMergeOnes){
            cData = this.filterBasedOnNumberOfIds(cData, 1, countOnes, 'All Unique Sequences')
        }
        if(this.state.checkedMergeTwos){
            cData = this.filterBasedOnNumberOfIds(cData, 2, countTwos, 'All Sequences With Two Reads')
        }
        if(this.state.checkedMergeThrees){
            cData = this.filterBasedOnNumberOfIds(cData, 3, countThrees, 'All Sequences With Three Reads')
        }
    
        cData.sort((a, b) => {
            return a.data - b.data
        })
    
        cData.forEach(d => {
            chartData.push(d.data);
            chartLabels.push(d.label);
            backgroundColors.push(d.color);
        })
    
        return {
            datasets: 
            [{
                data: chartData,
                backgroundColor: backgroundColors
            }],
            labels: chartLabels
        }
    }

    handleChange = which => event => {
        this.setState({
            [which]: event.target.checked
        })   
    }

    mapControlToFormControlSwitch = controls => {
        return controls.map(control => {
            return (
                <FormControlLabel 
                    key={control.state}
                    control = {
                        <Switch                        
                        color="primary"
                        checked={this.state[control.state]}
                        onChange={this.handleChange(control.state)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    }
                    label={control.label}
                />
            )
        })
    }

    render(){

        const allControls = [
            {
                label: 'Locus',
                controls: this.mapControlToFormControlSwitch(this.state.controls.locus)
            },
            {
                label: 'Productivity',
                controls: this.mapControlToFormControlSwitch(this.state.controls.productive)
            },
            {
                label: 'Merge',
                controls: this.mapControlToFormControlSwitch(this.state.controls.merges)
            }
        ]

        let renderedControls = allControls.map(el => {
            return (
                <Grid item xs={12} sm={4} key={el.label}>
                    <FormControl >
                    <FormLabel >{el.label}</FormLabel>
                        <FormGroup>
                        {el.controls}
                        </FormGroup>
                    </FormControl>
                </Grid>
            )
        })

        let options = {...this.state.options};
        let data = this.constructData(this.props.combinations);

        if(data.datasets[0].data.length < 400){
            options.elements.arc.borderWidth = 1;
        }else{
            options.elements.arc.borderWidth = 0;
        }

        return(
            <Grid container spacing={2}>
                <Grid item xs={12}>                
                    <Paper className={css.FilterBox}>
                        <h2>Filter:</h2>
                        <Grid container spacing={2}>
                            {renderedControls} 
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={css.PaperWrapper}>    
                        <h2>Dartboard of different variable sequences</h2>
                        <Doughnut class={css.FillDart} data={data} options={this.state.options}/>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
    
}

export default dartboard;