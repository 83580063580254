import * as actionTypes from './actionTypes'
import axios from 'axios'

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId
    }
}

export const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, +expirationTime * 1000)

    }
}

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const authClearError = () => {
    return {
        type: actionTypes.AUTH_ERROR_CLEAR
    }
}

export const auth = (email, password, isSignUp, remember) => {
    return dispatch => {
        dispatch(authStart());
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBcyhu-8oZZrncCnfkOOWWX5O4VqcehKNw'

        if(!isSignUp){
            url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBcyhu-8oZZrncCnfkOOWWX5O4VqcehKNw'
        }

        axios.post(url, {
            email: email,
            password: password,
            returnSecureToken: true
        }).then(res => {
            const expirationDate = new Date(new Date().getTime() + ((res.data.expiresIn)*1000))
            if(remember){
                localStorage.setItem('token', res.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', res.data.localId);
                dispatch(checkAuthTimeout(res.data.expiresIn))
            }
          
            dispatch(authSuccess(res.data.idToken, res.data.localId));
    
        }).catch(error => {

            dispatch(authFail(error.response.data.error))
        })
    }

}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token){
            dispatch(logout());
        }else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const newDate = new Date();
            if(expirationDate >  newDate){
                dispatch(authSuccess(token, localStorage.getItem('userId')))
                dispatch(checkAuthTimeout((expirationDate.getTime() - newDate.getTime())/1000))
            }else{
                dispatch(logout());
            }        
        }
    }
}