import React from 'react';

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

const sideMenu = props => {

    let items = Object.keys(props.items).map(el => {
        return (
            <ListItem button key={el} onClick={() => props.clicked(props.items[el].label)} >
                <ListItemText primary={props.items[el].label}/>
            </ListItem>
        )
    })

    return (
            <List >
                {items}
            </List>

    )
}

export default sideMenu;