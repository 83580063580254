import React, {Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';
import * as actions from './store/actions'

import Index from '../src/containers/Index/Index';
import Upload from '../src/containers/Upload/Upload';
import Dashboard from '../src/containers/Dashboard/Dashboard'
import Layout from '../src/hoc/Layout/Layout';
import Login from '../src/containers/Login/Login';
import Logout from '../src/containers/Logout/Logout'
import Results from '../src/containers/Results/Results'

class App extends Component {

  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  render(){

    const routeDefinitions = [
      {
        link: '/',
        label: 'Home'
      },
      {
        link: '/upload',
        label: 'Upload'
      },
      {
        link: '/dashboard',
        label: 'Dashboard'
      }
    ]

    if(this.props.userId){
      routeDefinitions.push(
        {
          link: '/results',
          label: 'data'
        },
        {
        link: '/logout',
        label: 'Logout'
      })
    }else{
      routeDefinitions.push({
        link: '/login',
        label: 'Login'
      })
    }

    let routes = (
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <Route path="/login" component={Login} /> 
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/upload" component={Upload} />
          <Route path="/" exact render={() => <Index routes={routeDefinitions} />} />
          
        </Suspense>     
      </Switch>
    )

    if(this.props.userId){
      routes = <Switch>
        <Suspense fallback={<div>Loading...</div>}>
        <Route path="/login" component={Login} /> 
          <Route path="/logout" component={Logout} /> 
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/upload" component={Upload} />
          <Route path="/results" component={Results} />
          <Route path="/" exact render={() => <Index routes={routeDefinitions} />} />
          
          
        </Suspense>     
      </Switch> 
    }

    let layout = (
      <Layout routes={routeDefinitions}>
         {routes}
      </Layout>
    )

    if(this.props.history.location['pathname'] === '/'){
      layout = (
        <div>
          {routes}
        </div>
      );
    }

    return (
      <div className="App">
        {layout}   
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
     userId: state.auth.userId
  }
}

const mapDispatchToProps = dispatch => {
  return {
     onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(App));
