import React, {Fragment} from 'react';

import css from './MainNavigation.module.css';

import { withRouter } from 'react-router-dom';
import classes from './MainNavigation.module.css'
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SideDrawer from './SideDrawer/SideDrawer'

const mainNavigation = (props) => {

    let classNames = [];
 
    if(props.isTransparent){
        classNames.push(css.Transparent)
    }

    const routes = Object.keys(props.routes).map(el => {
        return <Button 
            color="inherit" 
            key={el}
            onClick={() => props.history.push(props.routes[el].link)}>
                {props.routes[el].label}
            </Button>
    })

    return (
        <Fragment>
            <SideDrawer routes={props.routes} showDrawer={props.showDrawer} onClose={props.onToggle} className={css.SideMenu}/>
            <AppBar 
                position="static" 
                color={props.isTransparent ? 'default' : props.color} 
                className={classNames.join(' ')}>
            <Toolbar>
                <Box display={{xs: 'block', sm: 'none'}}>
                    <IconButton edge="start" 
                        className={classes.menuButton} 
                        color="inherit"
                        aria-label="menu"
                        onClick={() => props.onToggle()}>
                        <MenuIcon />
                    </IconButton>
                </Box>  
                <Box display={{xs: 'none', sm: 'block'}}>
                {routes}
                </Box>         
            </Toolbar>
        </AppBar>
      </Fragment>

    )
}

export default withRouter(mainNavigation);