import React, { Component } from 'react';
import css from './Histogram.module.css';
import { Bar } from 'react-chartjs-2';

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'

class Histogram extends Component {

    state = {
        controls:{
            locus: [
                    {
                        state: 'checkedAlpha',
                        label: 'Alphas'
                    },
                    {
                        state: 'checkedBeta',
                        label: 'Betas'
                    },
                    {
                        state: 'checkedGamma',
                        label: 'Gammas'
                    }
                ],
            productive: [
                {
                    state: 'checkedProductive',
                    label: 'Productive'
                },
                {
                    state: 'checkedNonProductive',
                    label: 'Non Productive'
                }
            ]
        },
        checkedAlpha: true, 
        checkedBeta: true,
        checkedGamma: true,
        checkedProductive: true,
        checkedNonProductive: false
    }

    createHistogramData = combinations => {

    
        const combosets = [];

        if(this.state.checkedAlpha){
            combosets.push({
                combos: combinations.filter(e => e.jRegion.locus === 'ALPHA'),
                label: 'Alphas',
                color: 'rgba(0,255,0,0.5)'
            })
        }

        if(this.state.checkedBeta){
            combosets.push({
                combos: combinations.filter(e => e.jRegion.locus === 'BETA'),
                label: 'Betas',
                color: 'rgba(255,0,0,0.5)'
            })
        }

        if(this.state.checkedGamma){
            combosets.push({
                combos: combinations.filter(e => e.jRegion.locus === 'GAMMA'),
                label: 'Gammas',
                color: 'rgba(0,0,255,0.5)'
            })
        }

        let totalMax = 0;
        const datasets = [];
        combosets.forEach(set => {
            const values = [];
            let max = 0
            set.combos.forEach(combo => {
                combo.variableSequences.forEach(seq => {
                    if((this.state.checkedProductive && seq.aminoCombination.isProductive) || 
                    (this.state.checkedNonProductive && !seq.aminoCombination.isProductive)){
                        const index = seq.sequence.length;
                        max = index > max ? index : max;               
                        values[index] === undefined ? values.splice(index, 0, 1) : values[index]++;
                    }
                })
            })

            datasets.push({
                data: values,
                backgroundColor: set.color,
                label: set.label
            })

            totalMax = max > totalMax ? max : totalMax;
        })
        
        const chartData = {
            labels: Array.from(Array(totalMax).keys()),
            datasets: datasets
        }

        return chartData;

    }

    handleChange = which => event => {
        this.setState({
            [which]: event.target.checked
        })   
    }

    mapControlToFormControlSwitch = controls => {
        return controls.map(control => {
            return (
             
                <FormControlLabel 
                    key={control.state}
                    control = {
                        <div>
                        <Switch                        
                        color="primary"
                        checked={this.state[control.state]}
                        onChange={this.handleChange(control.state)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                    </div>
                    
                    }
                    label={control.label}
                />
                
            )
        })
    }

    render(){

        const data = this.createHistogramData(this.props.combinations);

        const allControls = [
            {
                label: 'Locus',
                controls: this.mapControlToFormControlSwitch(this.state.controls.locus)
            },
            {
                label: 'Productivity',
                controls: this.mapControlToFormControlSwitch(this.state.controls.productive)
            }
        ]

        let renderedControls = allControls.map(el => {
            return (
                <Grid item xs={12} sm={4} key={el.label}>
                    <FormControl >
                    <FormLabel >{el.label}</FormLabel>
                        <FormGroup>
                        {el.controls}                      
                        </FormGroup>
                    </FormControl>
                </Grid>
            )
        })

        const options = {
            legend: false,
            scales:  {
                xAxes: [
                    {
                        ticks: {
                            autoSkip: false
                        },
                        scaleLabel: {
                            display: true,
                            fontFamily: 'roboto',
                            labelString: 'Length of CDR3 Region'
                        }
                    }
                ]
            }
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Paper className={css.PaperWrapper}>
                        <h2>Filter:</h2>
                        <Grid container spacing={2}>
                        {renderedControls} 
                        </Grid>
                    </Paper>
                </Grid> 
                <Grid item xs={12} >
                    <Paper className={css.PaperWrapper}>
                        <Bar data={data} options={options}/>
                    </Paper>    
                </Grid> 
            </Grid>
        )
    }


}

export default Histogram;